<template>
    <v-layout row justify-center>
        <v-dialog v-model="dialog" scrollable persistent max-width="700px">
            <v-card ref="formCard">
                <v-card-title>
                    <span class="headline" v-if="formType === 'UPDATE'">Update Store Record</span>
                    <span class="headline" v-else>Create New Store Record</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="formEntry">
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 v-if="formType === 'UPDATE'">
                                    <v-text-field label="ID" :readonly="formType === 'UPDATE'"  v-model="form.id" required data-cy="form-id"></v-text-field>
                                </v-flex>
                                <v-flex xs12 >
                                    <v-text-field label="Store ID*" :readonly="formType === 'UPDATE'" v-model="form.storeId" required data-cy="form-store-id"></v-text-field>
                                </v-flex>
                                <v-flex xs12 v-if="formType === 'UPDATE'">
                                    <v-text-field label="Total Daily Transactions" :readonly="formType === 'UPDATE'" v-model="form.totalDailyTransactions" data-cy="form-daily-transactions"></v-text-field>
                                </v-flex>
                                <v-flex xs12 v-if="formType === 'UPDATE'">
                                    <v-text-field label="Current Day total" :readonly="formType === 'UPDATE'" v-model="form.currentDayTotal" data-cy="form-day-total"></v-text-field>
                                </v-flex>
                                <v-flex xs12 v-if="formType === 'UPDATE'">
                                    <v-text-field label="Current Hour Total" :readonly="formType === 'UPDATE'" v-model="form.currentHourTotal" data-cy="form-hour-total"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="Daily Limit" v-model="form.dailyLimit" data-cy="form-daily-limit"></v-text-field>
                                </v-flex>
                                <v-flex xs12 v-if="formType === 'UPDATE'">
                                    <v-text-field label="Date Created" :readonly="formType === 'UPDATE'" v-model="form.dateCreated" data-cy="form-date-created"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="Hourly Limit" v-model="form.hourlyLimit" data-cy="form-hourly-limit"></v-text-field>
                                </v-flex>
                                <v-flex xs12 v-if="formType === 'UPDATE'">
                                    <v-text-field label="Last Updated" :readonly="formType === 'UPDATE'" v-model="form.lastUpdated" data-cy="form-last-updated"></v-text-field>
                                </v-flex>
                                <v-flex xs12 v-if="formType === 'UPDATE'">
                                    <v-text-field label="Last Updated By" :readonly="formType === 'UPDATE'" v-model="form.lastUpdatedBy" data-cy="form-last-updated-by"></v-text-field>
                                </v-flex>
                                <!-- <v-flex xs12 sm6 md4 >
                                    <v-switch v-model="form.isEnabled" :label="`Is Enabled: ${form.isEnabled ? form.isEnabled.toString() : 'false'}`" data-cy="form-switch"></v-switch>
                                </v-flex> -->
                            </v-layout>
                        </v-container>
                    </v-form>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                    <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
    </template>
    
    <script>
    export default {
        props: {
            isShow: Boolean,
            formType: String,
            loadingDialog: Boolean,
            selectedItem: Object,
        },
        computed: {
            populatedForm() {
                return {
                    id: this.form.id,
                    storeId: this.form.storeId,
                    totalDailyTransactions: this.form.totalDailyTransactions,
                    currentDayTotal: this.form.currentDayTotal,
                    currentHourTotal: this.form.currentHourTotal,
                    dailyLimit: this.form.dailyLimit,
                    dateCreated: this.form.dateCreated,
                    hourlyLimit: this.form.hourlyLimit,
                    lastUpdated: this.form.lastUpdated,
                    lastUpdatedBy: this.form.lastUpdatedBy,
                    formType: this.formType
                }
            }
        },
        watch: {
            isShow: {
                handler(value) {
                    this.dialog = value
                }
            },
            loadingDialog: {
                handler(value) {
                    this.loading = value
                },
            },
            dialog: {
                handler(value) {
                    if (value === true) {
                        this.initializeForm()
                    }
                }
            },
            deep: true,
        },
        data() {
            return {
                dialog: this.isShow ?? false,
                loading: this.loadingDialog ?? false,
                form: {
                },
                rules: {
                    requiredField: v => !!v || "Requiredthis.price field.",
                    requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                }
            }
        },
        mounted() {
            this.initializeForm()
        },
        methods: {
            close() {
                this.dialog = false
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
                this.$emit('close-dialog', this.formType)
            },
            save() {
                if (this.$refs.formEntry.validate()) {
                    this.$emit('save-threshold', this.populatedForm)
                    this.$refs.formCard.$el.scrollIntoView({
                        behavior: 'smooth'
                    })
                }
            },
            resetForm() {
                this.$refs.formEntry.reset()
            },
            initializeForm() {
                if (this.formType === 'CREATED') {
                    this.form = {
                        storeId: undefined,
                        dailyLimit: undefined,
                        dateCreated: undefined,
                    }
                } else if (this.formType === 'UPDATE' && this.selectedItem) {
                    this.form = {
                        id: this.selectedItem.id,
                        storeId: this.selectedItem.storeId,
                        totalDailyTransactions: this.selectedItem.totalDailyTransactions,
                        currentDayTotal: this.selectedItem.currentDayTotal,
                        currentHourTotal: this.selectedItem.currentHourTotal,
                        dailyLimit: this.selectedItem.dailyLimit,
                        dateCreated: this.selectedItem.dateCreated,
                        hourlyLimit: this.selectedItem.hourlyLimit,
                        lastUpdated: this.selectedItem.lastUpdated,
                        lastUpdatedBy: this.selectedItem.lastUpdatedBy,
                    }
                }
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            onlyForCurrency ($event) {
                // console.log($event.keyCode); //keyCodes value
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    
                // only allow number and one dot
                if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.form.amount.indexOf('.') != -1)) { // 46 is dot
                    $event.preventDefault();
                }
    
                // restrict to 2 decimal places
                if(this.form.amount!=null && this.form.amount.indexOf(".")>-1 && (this.form.amount.split('.')[1].length > 1)){
                    $event.preventDefault();
                }
            }
        }
    }
    </script>
    