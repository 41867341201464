<template>
    <v-app id="inspire">
        <div class="px-4">
            <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
            <!-- <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" /> -->
            <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px',  color: '#1d6499' }">
                <v-icon :style="{ color: '#1d6499',paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large>mdi-cogs</v-icon> Store Threshold <span class="text-h5 font-weight-bold"> ({{totalStoreRecords}}) </span>
            </v-container>
            <v-card elevation="2" outlined shaped tile class="pa-1">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card-title>
                        <v-container grid-list-md text-xs-center>
                            <v-layout row wrap>
                                <v-flex>
                                    <div class="d-flex">
                                        <span class="mt-4 mr-2">Search By:</span>
                                        <v-text-field class="mr-3" label="Store ID" v-model="query.storeId" single-line outlined @change="search($event, 'storeId')" data-cy="module-store-id"></v-text-field>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
                <div class="d-flex flex-no-wrap justify-space-between mr-lg-10">
                    <!-- <v-card-title> -->
                        <v-container grid-list-md text-xs-center>
                            <v-layout wrap class="float-lg-right">
                                <v-flex xs12 sm4>
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown"  style="min-width: 200px;" class="mt-2" color="primary" @click="resetInputs()" data-cy="button-reset">
                                        <v-icon dark left> mdi-history </v-icon> Reset
                                    </v-btn>
                                </v-flex>
                                <v-flex xs12 sm4>
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown"  style="min-width: 200px;" class="mt-2" color="primary" @click="queryData(true)" data-cy="button-search">
                                        <v-icon dark left> mdi-magnify</v-icon> Search
                                    </v-btn>
                                </v-flex>
                                <v-flex xs12 sm4>
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown"  style="min-width: 200px;" class="mt-2" color="primary" @click.stop="showStoreRecordForm('CREATED')" data-cy="button-create">
                                        <v-icon dark left> mdi-plus</v-icon>Create New Store Threshold
                                    </v-btn>
                                    <store-record-form ref="storeRecordForm" :selected-item="selectedStoreRecord" :is-show="showFormDialog"  :form-type="storeRecordFormType" v-on:close-dialog="closeDialog" v-on:save-store-record="saveStoreRecord" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                </div>
            </v-card>
    
            <v-data-table :hide-default-header="true" :headers="headers" :items="displayStoreRecords"
                :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options"
                :server-items-length="totalStoreRecords" class="elevation-2 mt-4 px-4"
                :footer-props="{'items-per-page-options': [10, 20, 50]}">
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text">
                                <span class="d-flex justify-center">{{header.text}}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.storeId }} </span></td>
                            <!-- <td> <span class="d-flex justify-center"> {{ item.currentDay }} </span></td> -->
                            <td> <span class="d-flex justify-center"> {{ item.totalDailyTransactions }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.currentDayTotal }} </span></td>
                            <!-- <td> <span class="d-flex justify-center"> {{ item.currentHour }} </span></td> -->
                            <td> <span class="d-flex justify-center"> {{ item.currentHourTotal }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.dailyLimit }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.hourlyLimit }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.lastUpdated }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.lastUpdatedBy }} </span></td>
                            <td>
                                <span class="d-flex justify-center">
                                    <!-- <v-btn fab dark x-small color="primary" class="mr-2" @click.stop="showTerminalForm('VIEW' ,item)" v-if="hasPermission('ROLE_TDM_VIEW_TERMINAL')">
                                        <v-icon dark medium>mdi-eye</v-icon>
                                    </v-btn> -->
                                    <v-btn fab dark x-small color="primary" @click.stop="showStoreRecordForm('UPDATE', item)">
                                        <v-icon dark medium>mdi-pencil</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        Found no results.
                    </v-alert>
                </template>
            </v-data-table>
        </div>
    </v-app>
    </template>
    
    <script>
    import CircuitBreakerAPI from '@/api/CircuitBreakerAPI.js'
    import Loading from '@/views/components/Loading.vue'
    import StoreRecordForm from '@/views/components/StoreRecordForm.vue'
    import {
        mapGetters
    } from 'vuex';
    
    export default {
        name: 'Terminals',
        components: {
            Loading,
            StoreRecordForm,
        },
        data() {
            return {
                headers: [],
                displayStoreRecords: [],
                remainderStoreRecords: [],
                loading: true,
                loadingMessage: "",
                loadingDialog: false,
                loadingFinished: false,
                storeRecordFormType: 'CREATE',
                selectedStoreRecord: {},
                page: 1,
                totalStoreRecords: 0,
                numberOfPages: 0,
                options: {
                    rowsPerPage: 10
                },
                query: {
                    storeId: '',
                },
                showFormDialog: false,
                isHtml: false
            }
        },
        watch: {
            options: {
                handler() {
                    console.log('1')
                    this.queryData()
                    // this.setData()
                },
            },
            deep: true,
        },
        computed: {
            //get role attributes
            ...mapGetters([
                'attributes',
                'user',
                'authorities'
            ]),
        },
        async mounted() {
            this.setHeaders()
            //this.queryData()
        },
        methods: {
            resetInputs() {
                this.query.storeId = ''
                this.queryData();
            },
            hasPermission(authority) {
              if(this.authorities.some(x => x.name === authority)){
                return true
              }
              return false
            },
            async queryData(isSearch = false) {
                this.loading = true
                if (isSearch) {
                    this.options.page = 1
                }
                const {
                    page,
                    itemsPerPage
                } = this.options;
                let pageNumber = page - 1;
                try {
                    const limit = itemsPerPage
                    const listResponse = await CircuitBreakerAPI.getAllStoreRecord(pageNumber, limit, this.query)
                    if (!listResponse || listResponse.error) {
                        //error getting data
                        console.log(`${listResponse.error}`)
                    } else {
                        var filteredList = listResponse.terminals
                        if (page > 1 && this.remainderStoreRecords.length > 0) {
                            filteredList = this.remainderStoreRecords.concat(filteredList)
                        }
                        this.totalStoreRecords = listResponse.totalItems
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayStoreRecords = filteredList.slice(0, end)
                        this.remainderStoreRecords = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = listResponse.pageCount
                    }
                    this.loading = false
                } catch (e) {
                    console.log(e)
                    this.loading = false
                }
            },
            async setData() {
                // this.loading = true
                // this.loadingDialog = true
                // this.loadingMessage = `Fetching data, please wait.`
                // this.loadingFinished = false
                // try {
                //     //call API for threshold
                // } catch (e) {
                //     console.log(e);
                // }
            },
            setHeaders() {
                var headers = [{
                        text: 'ID',
                        value: 'id'
                    },
                    {
                        text: 'Store ID',
                        value: 'storeId'
                    },
                    {
                        text: 'Daily Transactions',
                        value: 'totalDailyTransactions'
                    },
                    {
                        text: 'Current Day Total',
                        value: 'currentDayTotal'
                    },
                    {
                        text: 'Current Hour Total',
                        value: 'currentHourTotal'
                    },
                    {
                        text: 'Daily Limit',
                        value: 'dailyLimit'
                    },
                    {
                        text: 'Date Created',
                        value: 'dateCreated'
                    },
                    {
                        text: 'Hourly Limit',
                        value: 'hourlyLimit'
                    },
                    {
                        text: 'Last Updated',
                        value: 'lastUpdated'
                    },
                    {
                        text: 'Last Updated By',
                        value: 'Last Updated By'
                    },
                    {
                        text: 'Actions',
                        value: 'controls',
                        sortable: false
                    }
                ]
                this.headers = headers
            },
            showStoreRecordForm(what, item = {}) {
                this.storeRecordFormType = what
                this.selectedStoreRecord = item
                this.showFormDialog = true
            },
            closeDialog(type) {
                console.log('type:', type);
                if (type === 'CREATED' || type === 'UPDATE' || type === 'VIEW') {
                    this.showFormDialog = false
                } else if (type === 'loading') {
                    this.loadingDialog = false
                    this.queryData();
                } else if (type === 'loading-error') {
                    this.loadingDialog = false
                }
            },
            async saveStoreRecord(value) {
                this.loadingDialog = true
                this.isHtml = true
                this.loadingFinished = false
                if (value) {
                    console.log(value)
                    if (value.formType === 'CREATED') {
                        this.loadingMessage = `Creating new store record <strong><i>${value.storeId}</i></strong>`
                        setTimeout(async () => {
                            const createResponse = await CircuitBreakerAPI.createStoreRecord(value)
                            console.log(createResponse)
                            this.loadingFinished = true
                            if (!createResponse || createResponse.error) {
                                this.isHtml = false
                                this.loadingMessage = createResponse.error
                            } else {
                                this.loadingMessage = `Successfully created store record <strong><i>${value.storeId}</i></strong>`
                                this.showFormDialog = false
                                this.$refs.storeRecordForm.resetForm()
                            }
                        }, 2000);
    
                    } else if (value.formType === 'UPDATE') {
                        this.loadingMessage = `Updating terminal <strong><i>${value.terminalId}</i></strong>`
    
                        setTimeout(async () => {
                            const updateResponse = await CircuitBreakerAPI.updateStoreRecord(value)
                            this.loadingFinished = true
                            if (!updateResponse || updateResponse.error) {
                                this.isHtml = false
                                this.loadingMessage = updateResponse.error
                            } else {
                                this.loadingMessage = `Successfully updated store <strong><i>${value.terminalId}</i></strong> record`
                                this.showFormDialog = false
                                this.$refs.storeRecordForm.resetForm()
                            }
                        }, 2000);
                    } else {
                        this.loadingMessage = "Invalid command"
                        this.loadingFinished = true
                    }
                } else {
                    this.loadingMessage = "Error processing data."
                    this.loadingFinished = true
                }
            },
            // viewTerminal(terminal) {
            //     this.details.title = terminal.terminalId
            //     this.details.item = terminal
            //     this.isShowDetails = true
            // },
            search(event, type) {
                this.query[type] = event
            }
        }
    };
    </script>
    
    <style scoped>
    .v-data-table {
        max-width: 100%
    }
    </style>
    